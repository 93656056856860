.succriber {
  width: 100%;
}
.suscribers > form > input {
  background: transparent !important;
  border: none;
}
.s-heading {
  position: relative;
  color: #000;
  font-size: 5rem;
  width: 230px;
  font-family: "Just Another Hand", cursive;
  font-weight: 600;
  letter-spacing: 3px;
  margin-right: 2rem;
  text-align: center;
}
.--btn-right-icon {
  margin-left: 1rem;
  width: 6rem;
  margin-top: 0.5rem;
  overflow: hidden;
  background-color: var(--btn-sec);
  color: #fff;
  transition: 0.3s ease-in-out;
}
.--btn-right-icon:hover {
  transform: translatex(10px);
}

@media screen and (max-width: 600px) {
  .s-heading {
    margin-top: 50px !important;
  }
}

@media screen and (max-width: 800px) {
  .s-heading {
    margin-top: 0px;
    text-align: center;
    width: 100%;
  }
  .succriber {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .--btn-right-icon {
    margin-left: 1rem;
    width: 6rem;
    margin-top: 1rem;
    overflow: hidden;
    background-color: var(--btn-sec);
    color: #fff;
    transition: 0.3s ease-in-out;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .s-heading {
    text-align: center;
    width: 100%;
  }
}
