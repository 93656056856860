.w-270 {
  width: 270px;
}
label {
  font-weight: 500;
}

.profile-photo {
  width: 100%;
  // border: 1px solid #fff;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  & img {
    width: 200px;
    height: 200px;
    border: 2px solid #fff;
    border-radius: 50%;
  }

  & h3 {
    color: #fff;
    font-size: 2rem;
    text-align: center;
  }
}
