.footer {
  background-color: #1c2127;
  color: #fff;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 1rem 2rem;
  .right {
    display: flex;
    justify-content: center;
    text-align: center;
    div {
      margin-right: 3px;
    }
  }
  .link {
    color: #fff;
    &:hover {
      font-size: 1.5rem;
      color: var(--btn-sec);
    }
  }
  i {
    color: red;
  }
  p {
    color: #fff;
  }
  .our {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}
