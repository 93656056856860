.chart-container {

  width: 80px;
  height: 80px;
  margin: 5px;
}

.chart-container .amount {
  border: 1px solid black;
}
.chart-container .month {
  color: #fff;
  background-color: var(--btn-sec);
  height: 40px;
}
