.--arrow {
  // border: 2px solid var(--nav-bg);
  // border-radius: 50%;
  background: var(--nav-bg);
  color: #fff;
  width: 2.5rem;
  height: 5rem;
  padding: 5px 3px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.--arrow:hover {
  background: #000000;
}

.--next {
  right: 1.5rem;
  color: #fff;
}

.--prev {
  left: 1.5rem;
  color: #fff;
}
@media screen and (max-width: 800px) {
  .--card-testomonial {
    height: 425px;
  }

  .--top {
    top: 100px;
  }
}
.item img {
  object-fit: cover;
}
