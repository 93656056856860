.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4rem 3rem;
  cursor: pointer;
}
.gallery .--img-card {
  object-fit: cover;
  height: 250px;
  width: 300px;
  margin: 1rem 1rem;
}
.gallery .--img-card img {
  object-fit: cover;
  height: 250px;
  width: 100%;
  transition: 0.3s all ease-in-out;
}
.gallery .--img-card img:hover {
  object-fit: cover;
  transform: scale(1.05);
}

.--p-a {
  position: absolute;
}

.--p-f-b {
  position: fixed;
  background-color: transparent;
  z-index: 50;
}
.--main-box {
  width: 80%;
}

@media screen and (max-width: 600px) {
  .--main-box {
    width: 100%;
  }
}
.--main-box .img-box {
  height: 50rem;
}
.img-box img {
  width: 100%;
  height: inherit;
  object-fit: contain;
}
.blur-bg {
  position: fixed;
  height: 90vh;
  width: 100%;
  top: 10%;
  background-color: #666060cb;
}
