.left-side {
  height: 45.8rem;
  width: 100%;
}
.left-side img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.right-side {
  position: relative;
  padding: 2rem 3rem;
}
.right-side ul {
  list-style-type: none;
}
.right-side ul li {
  margin: 7px 0;
}
.right-side ul li i {
  color: var(--nav-bg);
  margin-right: 8px;
}
