.formcard{
  background-color: #fff !important;
}
.blur-bg {
  position: fixed;
  height: 90vh;
  width: 100%;
  top: 10%;
  background-color: #666060cb;
}
.--p-r {
  position: relative;
}
.--p-f-b {
  position: fixed;
  background-color: #fff;
  top: 13rem;
}
.--p-a {
  position: absolute;
}

@media screen and (max-width: 799px) {
  .banner {
    height: 300px;
    /* margin-top: -17.9rem;
    margin-bottom: 22rem; */
  }

}

