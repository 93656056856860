/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --font-family: "Poppins", sans-serif;
  --dark-blue: #0a1930;
  --light-blue: #1f93ff;
  --nav-bg: #3dbd84;

  --color-white: #fff;
  --color-dark: #333;

  --color-grey: #eee;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  --color-purple: #9d0191;
  --color-orange: #ff7722;

  --color-primary: #007bff;
  --color-success: #28a745;
  --color-danger: orangered;

  --bg-1: #321fdb;
  --bg-2: #3399ff;
  --bg-3: #4f5d73;
  --bg-4: #2eb85c;

  --btn-sec: #443dbd;
}

@import url("https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Katibeh&display=swap");

.--font-j {
  font-family: "Montserrat", Sans-serif;
  font-size: 1.6rem;
}

.--font-k {
  color: #443dbd;
  font-size: 6rem;
  font-family: "Katibeh", serif;
}
.--just {
  font-family: "Just Another Hand", cursive;
}
.poppins {
  font-family: "Poppins", sans-serif;
}

.--text-pink {
  color: #bd3d76;
}

.--p-text {
  font-weight: 400;
}

.--bg-1 {
  background-color: var(--bg-1);
}
.--bg-2 {
  background-color: var(--bg-2);
}
.--bg-3 {
  background-color: var(--bg-3);
}
.--bg-4 {
  background-color: var(--bg-4);
}

.--btn-sec {
  background-color: var(--btn-sec) !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  font-family: var(--font-family);
}

section {
  width: 100%;
  padding: 0 0;
  top: 0;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

/* UTILITY CLASSES */

/* Flex */
.--flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.--flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.--flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.--flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.--flex-gap {
  gap: 20px;
}

.--dir-column {
  flex-direction: column;
}

.--flex-dir-column {
  display: flex;
  flex-direction: column;
}
.--flex-dir-row {
  display: flex;
}
.--flex-row {
  display: flex;
}
.--flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.--f-warp {
  flex-wrap: wrap;
}

.--align-center {
  display: flex;
  align-items: center;
}

.--80vh {
  height: 80vh;
}

.--90vh {
  height: 90vh;
}
.--100vh {
  height: 100vh;
}

.--mh-100vh {
  min-height: 100vh;
}

/* Grid */
.--grid-15 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}
.--grid-25 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}
.--grid-50 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
  row-gap: 1rem;
  column-gap: 1.5rem;
}

/* Center All */
.--center-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
  text-align: center;
}

/* Heading */
h1,
h2,
h3,
h4 {
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-dark);
  margin-bottom: 1rem;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--color-dark);
}
.--text-xl {
  font-size: 4.5rem;
}
.--text-lg {
  font-size: 4rem;
}

.--text-md {
  font-size: 3rem;
}
.--text {
  font-size: 1.5rem;
}
.--text-sm {
  font-size: 1.2rem;
  font-weight: 300;
}
.--text-smd {
  font-size: 1.8rem;
  font-weight: 600;
}
.--text-20 {
  font-size: 2rem;
  font-weight: 600;
}

.--text-p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--color-dark);
}

.--fw-bold {
  font-weight: 600;
}
.--fw-thin {
  font-weight: 200;
}

/* Text Color */
.--text-light {
  color: #fff;
}
.--text-dark {
  color: #000;
}

.--color-primary {
  color: #007bff;
}
.--color-danger {
  color: orangered;
}
.--color-success {
  color: #28a745;
}

.--color-white {
  color: #fff;
}

/* Center Text */
.--text-center {
  text-align: center;
}
.--text-right {
  text-align: right;
}
.--text-left {
  text-align: left;
}
.--text-justify {
  text-align: justify;
}

.--cursor-pointer {
  cursor: pointer;
}
.--z-20 {
  z-index: 20 !important;
}

.--z-50 {
  z-index: 50;
}

/* Card */
.--card {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

/* Margin */
.--m {
  margin: 1rem;
}
.--ml {
  margin-left: 1rem;
}
.--mr {
  margin-right: 1rem;
}

.--mb {
  margin-bottom: 1rem;
}
.--mt {
  margin-top: 1rem;
}

.--my {
  margin: 1rem 0;
}
.--mx {
  margin: 0 1rem;
}

.--m2 {
  margin: 2rem;
}

.--ml2 {
  margin-left: 2rem;
}
.--plc {
  padding: 10rem;
}
.--mr2 {
  margin-right: 2rem;
}

.--mb2 {
  margin-bottom: 2rem;
}

.--mb3 {
  margin-bottom: 3rem;
}

.--my2 {
  margin: 2rem 0;
}
.--my3 {
  margin: 6rem 0 4rem 0;
}

.--my4-c {
  margin: 6rem 0 4rem 0;
}

.--mt2 {
  margin-top: 2rem;
}
.--mt3 {
  margin-top: 3rem;
}
.--mt4 {
  margin-top: 4rem;
}
.--mt5 {
  margin-top: 6rem;
}

.--mx2 {
  margin: 0 2rem;
}

.--m3 {
  margin: 3rem;
}

.-mt-2 {
  margin-top: -2.7rem;
}

.--p1x {
  padding: 1rem 3rem;
}
.--p2x {
  padding: 1rem 5rem;
}
.--pxy {
  padding: 0.3rem 1rem;
}
.--px2y {
  padding: 0rem 2.5rem;
}
.--pxy1 {
  padding: 1rem 2.5rem;
}
.--pxy2 {
  padding: 2rem 3rem;
}
.--pxy3 {
  padding: 3rem 4rem;
}
.--px2y3 {
  padding: 3rem 2rem;
}

/* Padding */
.--p {
  padding: 1rem;
}
.--p2 {
  padding: 2rem;
}
.--pl {
  padding-left: 0.5rem;
}
.--py {
  padding: 1rem 0;
}
.--py1_5 {
  padding: 1.5rem 0;
}
.--py2 {
  padding: 2rem 0;
}
.--py3 {
  padding: 3rem 0;
}
.--px {
  padding: 0 1rem;
}
.--px1_5 {
  padding: 0 1.5rem;
}
.--px2 {
  padding: 0 2rem;
}
.--px3 {
  padding: 0 3rem;
}

.--btn {
  font-size: 1.6rem;
  font-weight: 400;
  padding: 6px 8px;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.--btn:hover {
  transform: translateY(-2px);
}

.--btn-lg {
  padding: 8px 10px;
}

.--btn-block {
  width: 100%;
}

.--btn-primary {
  color: #fff;
  background: #007bff;
}
.--btn-secondary {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
.--btn-danger {
  color: #fff;
  background: orangered;
}

.--btn-success {
  color: #fff;
  background: #28a745;
}

.--btn-red {
  color: #fff;
  background: rgba(189, 9, 9, 0.847);
}

/* Background */
.--bg-light {
  background: #fff;
}
.--bg-dark {
  background: var(--color-dark);
}
.--bg-primary {
  background: var(--color-primary);
}
.--bg-success {
  background: var(--color-success);
}
.--bg-grey {
  background: var(--color-grey);
}
.--bg-danger {
  background: var(--color-danger);
}

.--form-control {
  font-size: 1.6rem;
  font-weight: 300;
}

.--form-control > * {
  margin: 5px 0;
}

.--form-control input {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}

.--form-control .t input {
  font-size: 1.6rem;
  font-weight: 300;
  margin-top: -10px;
  /* padding: 8px 1rem; */
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
  min-width: 22rem;
  width: 30rem;
  /* background: #d4d4d431; */
}

.--form-control select {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
}

.--form-control > label {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  color: var(--color-dark);
}
.--form-control .t label {
  font-size: 3rem;
  font-weight: 400;
  display: inline-block;
  color: #fff;
}

.--form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: unset;
}
.--form > input {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}

@media screen and (max-width: 600px) {
  .--flex-dir-column {
    flex-direction: column;
  }

  .--f-cloumn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .--grid-50 {
    grid-template-columns: repeat(auto-fit, minmax(100%, 2fr));
  }
  .--m3 {
    margin: 0;
  }
  .--plc,
  .--p1x {
    padding: 1rem 1rem;
  }

  .--font-k {
    color: #443dbd;
    font-size: 4.5rem;
    font-family: "Katibeh", serif;
  }
}

@media screen and (max-width: 870px) {
  .--wrap-rev {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;
  }
  .--flex-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .d-m-none {
    display: none;
  }
}

.--block {
  display: block;
}
.--inline-block {
  display: inline-block;
}

.--width-100 {
  width: 100% !important;
}

.--w-80 {
  width: 80% !important;
}
.--w-90 {
  width: 90% !important;
}
.--w-50 {
  width: 50% !important;
}
.--w-48 {
  width: 48% !important;
}

.--w-40 {
  max-width: 45% !important;
  min-width: 35rem;
}

.--width-500px {
  width: 500px;
}

.--line {
  position: relative;
}
.--line::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 5rem;
  height: 3px;
  margin-bottom: 1rem;
  background: rgb(217, 8, 170);
  background: linear-gradient(
    135deg,
    rgba(163, 1, 191, 1) 44%,
    rgba(217, 8, 170, 1) 57%
  );
}

.--list-style-none {
  list-style: none;
}

.--profile-img {
  width: 6rem;
  height: 6rem;
  border: 1px solid #ccc;
  border-radius: 50%;
}

a {
  font-size: 1.5rem;
  color: var(--dark-blue);
  text-decoration: none;
  transition: all 0.2s;
}

a:hover {
  color: var(--color-dark);
  font-size: 1.6rem;
}

.ql-editor {
  min-height: 200px;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  font-size: 1rem;
}

.pagination .page-num {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: normal;
  color: #333;
  border: 1px solid #333;
  margin: 2px;
}

.pagination .page-num:hover {
  color: #fff;
  background-color: var(--light-blue);
}
.activePage {
  color: #fff;
  background-color: var(--light-blue);
  height: 100%;
}

.active {
  position: relative;
  color: var(--color-danger);
}

.active::after {
  content: "";

  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.formcard {
  width: 100%;
  max-width: 500px;
  padding: 1rem;
  font-size: 1.6rem;
}

.formgroup {
  border: 1px solid var(--dark-blue);
  padding: 5px;
}

.--hr {
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
}

.activeImg {
  border: 1px solid var(--color-orange);
}

.--underline {
  border-bottom: 1px solid #ccc;
}

.react-star-rate__star {
  color: red;
}

.--cursor {
  cursor: pointer;
}

.dropdown-button {
  font-size: 1.5rem;
  text-decoration: none;
  transition: all 0.2s;
  color: white;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  display: flex;
  width: fit-content;
  background-color: var(--nav-bg);
  flex-wrap: wrap;
  flex-direction: column;
  padding: 1rem;
  z-index: 10;
}
.dropdown div {
  padding-bottom: 3px;
}

.--custome-input {
  width: 100%;
}
.--custome-input input {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
  font-family: var(--font-family);
  width: 30rem;
}

.--custome-input input[type="number" i] {
  writing-mode: horizontal-tb !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.--textarea {
  border: none;
}
.--textarea:focus {
  border: none;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */


.loader-bg{
  position: absolute;
  top:8rem;
  left: 0;
  height: 90vh;
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}


.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}