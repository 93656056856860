// Footer section
.footer-section {
  padding: 4rem 0;
  background-color: #222831;
  .--div {
    padding: 1rem 3rem;
  }
}
.--xs-mb {
  margin-bottom: 1rem;
}
.footer-logo {
  display: flex;
  align-items: center;
}

.footer-icons {
  text-align: end;
  h4 {
    margin: 0px 3.5rem 20px 0rem;
  }
  span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a {
      font-size: 2rem;
      margin-right: 10px;
      color: #fff;
      &:hover {
        color: var(--btn-sec);
      }
    }
  }
}

.footer-links {
  width: 100%;
  margin-top: 3rem;
  div {
    span {
      font-size: 1.35rem !important;
    }
  }
  ul {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;

    padding: 0;

    li {
      text-decoration: none;
      color: #fff;
      height: 1.5rem;
      padding-right: 1rem;
      margin: 1rem 1.5rem 1rem 0;
      // border-right: 2px solid #fff;
      font-size: 1.5rem;
      .--content::before {
        content: "";
        position: absolute;
        margin-left: -1.5rem;
        margin-top: 0.4rem;
        width: 2px;
        height: 1.5rem;
        background-color: #fff;
      }
      a {
        color: #fff;
      }
    }
  }
}

// Media Query 600px
@media screen and (max-width: 550px) {
  .footer-icons {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }

  .footer-logo {
    h1 {
      font-size: 3rem;
    }
  }
  h4 {
    margin-right: 2rem;
  }
  .--xs-mb {
    margin-bottom: 1rem;
  }
}
