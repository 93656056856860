.fixed {
  width: 100%;
  position: fixed;
  top: 0;
  transition: all 0.5s;
  z-index: 9;
}

header {
  width: 100%;
  background-color: var(--nav-bg);
  color: #fff;

  .header {
    width: 100%;
    height: 8rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .logo a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.6rem;
    width: 100%;
    line-height: 0.8;
    color: #fff;
    cursor: pointer;
    div {
      margin-left: 0.5rem;
      span {
        font-size: 1.7rem;
      }
    }
  }

  nav {
    width: 85%;
    display: flex;
    justify-content: space-between;
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;

      .logo-mobile {
        display: none;
      }

      li {
        margin: 8px 10px;
        a {
          color: #fff;
          padding: 0 10px;
          &:hover {
            color: rgb(0, 0, 0);
            font-weight: 600;
          }
        }
      }
    }
  }

  .navigation-link {
    color: #fff;
    padding: 0 10px;
    &:hover {
      color: rgb(0, 0, 0);
      font-weight: 600;
    }
  }

  .header-right {
    display: flex;

    span {
      margin: 0 5px;
      display: flex;
      align-items: flex-end;

      p {
        color: #fff;
      }
    }

    .links a {
      margin: 0 5px;
      color: #fff;
      &:hover {
        color: #000;
      }
      &.active {
        color: var(--color-danger);
      }
    }
    i {
      font-size: 1.7rem;
      cursor: pointer;
      &:hover {
        color: #443dbd;
      }
    }
    button {
      color: #fff;
      font-family: var(--font-family);
      font-weight: 600;
      background: #fff;
      border: 0;
      border-radius: 18px;
      padding: 8px 16px;
      transition: 0.3s;
      cursor: pointer;
      background-color: #443dbd;
      &:hover {
        color: #fff;
        background-color: #2f2b83;
      }
    }
  }

  .menu-icon {
    cursor: pointer;
    display: none;
  }

  @media screen and (max-width: 800px) {
    nav {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;
      height: 100vh;
      background-color: var(--nav-bg);
      padding: 1rem;
      z-index: 999;
      transform: translateX(-200%);
      transition: all 0.3s;

      .nav-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        transform: translateX();
        transition: all 0.3s;
      }

      .show-nav-wrapper {
        transform: translateX(100%);
      }

      ul {
        display: block;
        .logo-mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > * {
            cursor: pointer;
          }
        }
        li {
          padding: 5px 0;
          border-bottom: 1px solid #333;
          a {
            display: block;
          }
        }
      }

      .header-right {
        display: block;
        .cart {
          // display: block;
          // padding: 5px 0;
          border-bottom: 1px solid #333;
          // a {
          //   position: relative;
          //   p {
          //     position: absolute;
          //     top: -1rem;
          //     left: 5rem;
          //     font-weight: 500;
          //   }
          // }
        }

        .links {
          display: block;
          a {
            display: block;
            margin: 0;
            padding: 5px 0;
            border-bottom: 1px solid #333;
          }
        }
      }
    }

    .show-nav {
      transform: translateX(0);
    }
    .hide-nav {
      transform: translateX(-200%);
    }
    .menu-icon {
      display: flex;
      > * {
        margin-left: 2rem;
      }
    }
  }
}

.active {
  position: relative;
  color: #000;
  font-weight: 600;
}

// .active::after {
//   content: "";
//   position: absolute;
//   left: 0;
//   bottom: -3px;
//   width: 100%;
//   height: 2px;
//   background-color: #fff;
// }
