.--c-h-w {
  height: fit-content;
  width: 300px;
}
.--card-img {
  object-fit: cover;
  height: 210px;
  width: 200px;
  /* padding-top: 2rem; */
}
.--card-img img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  /* margin-top: 1rem; */
}
.--card-heading {
  font-weight: 600;
}

.--card-button {
  width: 100%;
  font-weight: 600;
  background-color: #443dbd;
  color: #fff;
  font-size: 1.6rem;
  padding: 8px 35px;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
}

.--custom-card-button {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 8px 35px;
  border: 1px solid transparent;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  background-color: #443dbd;
}
