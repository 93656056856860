.--event-card {
  max-width: 48%;
  min-width: 25rem;
  height: fit-content;
}
.--event-card img {
  height: 20rem;
  width: 100%;
  object-fit: cover;
  object-position: top;
}
/* .add-events{
    display: flex;
    align-items: ;
} */
.add-events button {
  position: fixed;
  padding: 10px 30px;
  border: 0;
  /* border-radius: 10px; */
  background-color: var(--color-primary);
  top: 2%;
  right: 3rem;
  cursor: pointer;
}
