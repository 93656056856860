.object {
  object-fit: contain;
  min-width: 300px;
  width: 560px;
  max-width: 100%;
}
.our-initiative {
  font-size: 7rem;
}
@media screen and (max-width: 800px) {
  .our-initiative {
    font-size: 5rem;
  }
}
