.sidebar {
  position: fixed;
  top: 0;
  left: 5rem;
  height: 100vh;
  width: 250px;
  background-color: #1f2937;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 10;
}

.sidebar.open {
  transform: translateX(-5rem);
}

.toggle-container {
  position: relative;
}

.sidebar-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
}

.grid1 {
  grid-area: header;
  height: max-content;
}
.grid2 {
  grid-area: menu;
  height: max-content;
}

.sidebar-container {
  display: grid;
  grid-template-areas:
    "header"
    "menu";
  gap: 10px 15px;
  background-color: #1f2937;
  padding: 10px;
}

.navlink {
  margin: 5px 0 10px 0;
}

.navlink .link {
  color: #fff;
}

.activeDlink {
  color: #fff;
}
.activeDlink .link {
  /* border-radius: 8px; */
  color: var(--color-orange);
  background-color: #756c6c !important;
  -webkit-box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.75);
}
