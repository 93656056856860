.item1 {
  grid-area: header;
}
.item2 {
  grid-area: menu;
  width: fit-content;
}
.item3 {
  grid-area: main;
}
.item4 {
  grid-area: right;
}
.item5 {
  grid-area: footer;
}

.grid-container {
  display: grid;
  grid-template-areas: "menu footer footer footer footer";
  gap: 10px 15px;
  padding: 10px;
}

.test {
  height: 140px;
  border-radius: 10px;
  padding: 10px 20px;
  width: 22rem;
  color: aliceblue;
}

.chart {
  width: 95%;
  max-width: 95%;
  min-width: 30%;
  display: flex;
  justify-content: center;
  height: 65dvh;
}
