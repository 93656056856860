.---card {
  min-width: 25rem;
  height: fit-content;
}
.--e-card img {
  height: 20rem;
  width: 100%;
  object-fit: cover;
  object-position: top;
}
