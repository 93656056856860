.banner {
  height: 300px;
  margin-top: 0.1rem;
}
.banner img {
  position: absolute;
  object-fit: cover;
  margin: auto;
}

.banner .heading {
  position: relative;
  font-weight: 900;
  color: #000000;
  letter-spacing: 2px;
  text-align: center;
  font-size: 3.5rem;
  font-family: "Raleway", sans-serif;
  z-index: 5;
  text-transform: uppercase;
}
